import React, { useState } from 'react'

//use to generate and display QR code base on a string
import QRCode from 'qrcode.react'

//UI Material
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'

//Icons
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CheckIcon from '@material-ui/icons/Check'

const EnrolmentForm = props => {
  //Input Fields
  const { username, password, passwordConfirmation, yubikeyId, comments } = props.values
  //components' states
  const { activeTab, step, isPasswordsMatch, isYubiKeyIdValid } = props.values
  //Methods
  const { TOTP, handleChange, switchTab, validateYubiKey } = props

  //Local states
  const [isPassShown, showPass] = useState(false)
  const [isPassConfShown, showPassConf] = useState(false)

  console.log('Rendering Form', props)
  return (
    <Paper square style={{ margin: '2rem 0', display: 'flex', justifyContent: 'center' }}>
      {step === 0 && (
        <>
          {/* ----------------------------------Tabs------------------------------------- */}
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={switchTab}
            aria-label="disabled tabs example">
            <Tab label="Password Only" />
            <Tab label="Yubikey" />
            <Tab label="Authenticator App" />
          </Tabs>
        </>
      )}
      {step === 1 && (
        <>
          {/* ----------------------------------Enrolment Fields------------------------------------- */}
          <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box m={1}>
              <FormControl fullWidth>
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input id="username" value={username} onChange={handleChange('username')} />
              </FormControl>
            </Box>
            <Box m={1}>
              <FormControl fullWidth error={isPasswordsMatch === false && true}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  value={password}
                  onChange={handleChange('password')}
                  type={isPassShown ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() => showPass(!isPassShown)}>
                        {isPassShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {isPasswordsMatch === false && (
                  <FormHelperText id="password-error">Passwords do not match</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box m={1}>
              <FormControl fullWidth error={isPasswordsMatch === false && true}>
                <InputLabel htmlFor="passwordConfirmation">Password Confirmation</InputLabel>
                <Input
                  id="passwordConfirmation"
                  type={isPassConfShown ? 'text' : 'password'}
                  value={passwordConfirmation}
                  onChange={handleChange('passwordConfirmation')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() => showPassConf(!isPassConfShown)}>
                        {isPassConfShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {isPasswordsMatch === false && (
                  <FormHelperText id="passwordConfirmation-error">
                    Passwords do not match
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            {/* ------------------------------------YubiKey------------------------------ */}
            {activeTab === 1 && (
              <Box m={1}>
                <FormControl fullWidth error={isYubiKeyIdValid === false}>
                  <InputLabel htmlFor="yubikey"> Press here and Touch yubikey</InputLabel>
                  <Input
                    id="yubikey"
                    value={yubikeyId}
                    onChange={handleChange('yubikeyId')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton tabIndex={-1} aria-label="valid yubikey">
                          {isYubiKeyIdValid === true && <CheckIcon color="primary" />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {isYubiKeyIdValid === false && (
                    <FormHelperText id="yubikey-error">Yubikey Id is invalid</FormHelperText>
                  )}
                </FormControl>
                <Button
                  onClick={validateYubiKey}
                  style={{ marginTop: '1rem' }}
                  variant="contained"
                  color="primary">
                  Validate YubiKey
                </Button>
              </Box>
            )}

            {/* -------------------------------Authenticator App---------------------------- */}
            {activeTab === 2 && (
              <Box m={1}>
                <Typography variant="body1" gutterBottom>
                  Please scan this QR code with your Authenticator App
                </Typography>
                <QRCode value={TOTP} />,
              </Box>
            )}
          </Box>
        </>
      )}
      {step === 2 && (
        <>
          {/* ----------------------------------Comment------------------------------------- */}
          <Box style={{ width: '100%' }} m={1}>
            <TextField
              id="standard-name"
              label="Comments"
              fullWidth
              multiline
              value={comments}
              onChange={handleChange('comments')}
              margin="normal"
            />
          </Box>
        </>
      )}
    </Paper>
  )
}
export default EnrolmentForm
