import React, { useState } from 'react'

//UI Material
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'

const GoogleAuth = ({ firebase, values, setValues, setIsAuthenticated }) => {
  const [error, setError] = useState(null)

  const googleSignIn = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      const result = await firebase.auth().signInWithPopup(provider)

      setValues({ ...values, username: result.user.email })
      setIsAuthenticated(true)

      console.info('Sign-In successfull')
    } catch (error) {
      const { code, message, email, credential } = error

      setError(message)
      console.error('Sign-In failed', code, message, email, credential)
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
      <Button onClick={googleSignIn} variant="contained" color="primary">
        Sign-In With Google
      </Button>
      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  )
}

export default GoogleAuth
