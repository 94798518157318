import React from 'react'

//Material UI
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

function getSteps() {
  return ['Enrolment Type', 'Form', 'Submission']
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Step 1: Select the enrolment type and click "Next"'
    case 1:
      return 'Step 2: Complete the form and click "Next?'
    case 2:
      return 'Step 3: Provide an optional comment and click "Submit" to submit the form to Panubo'
    default:
      return 'Unknown step'
  }
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const { handleBack, handleNext, handleReset, step, loading } = props
  const classes = useStyles()
  const steps = getSteps()

  return (
    <div className={classes.root}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {step === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(step)}</Typography>

            {/* Enrolment Form */}
            {props.children}

            <div style={{ display: 'flex', height: '70px', width: '100%', alignItems: 'center' }}>
              <Button disabled={step === 0} onClick={handleBack} className={classes.backButton}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {step === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>
              <Box style={{ marginLeft: 'auto' }}>{loading && <CircularProgress />}</Box>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
