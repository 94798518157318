import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Modal = props => {
  const { closeModal, isModalActive, refNumber } = props
  const [refCopied, setRefCopied] = useState(false)

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={isModalActive}>
      <DialogTitle id="simple-dialog-title">Enrolment successfull</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Find below the reference number to email to Panudo. Click "Copy to Clipboard" button and
          send this reference to team@panubo.com.
        </DialogContentText>
        <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-description">
          {refNumber}
        </DialogContentText>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item xs={5}>
            <CopyToClipboard text={refNumber} onCopy={() => setRefCopied(true)}>
              <Button variant="contained" color="primary" autoFocus>
                Copy to clipboard
              </Button>
            </CopyToClipboard>
          </Grid>
          {refCopied && (
            <Grid item xs={2}>
              <DialogContentText style={{ margin: '0' }}>{'Copied'}</DialogContentText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Button onClick={closeModal} color="secondary">
        Close
      </Button>
    </Dialog>
  )
}

export default Modal
