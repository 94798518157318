import React, { useState, useEffect } from 'react'
import axios from 'axios'
//Use to generate salt for the password hash (16 char 0-9/a-z/A-Z)
import randomstring from 'randomstring'
//Use to obtain a hash of the password in sha512crypt (using salt)
import { sha512crypt } from 'sha512crypt-node'
//Use to generate a 32 char base32 string for the totp secret
import speakeasy from 'speakeasy'

//Firebase
import * as firebase from 'firebase/app'
import 'firebase/auth'
import { firebaseConfig } from './conf'

//UI material
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

//Components
import Modal from './components/Modal'
import GoogleAuth from './components/GoogleAuth'
import Steppers from './components/Steppers'
import EnrolmentForm from './components/EnrolmentForm'
import Faqs from './components/Faqs'

//API URL
// const prodAPIURL = 'https://us-central1-pnb-enrol-stage-256001.cloudfunctions.net'
// const devAPIURL = '/pnb-enrol-stage-256001/us-central1'
// const apiURL = process.env.NODE_ENV === 'production' ? prodAPIURL : devAPIURL
const apiURL = ''

function App() {
  //Variable declaration
  const initialState = {
    isAuthenticated: false,
    enrolRef: '',
    username: '',
    password: '',
    passwordConfirmation: '',
    yubikeyId: '',
    comments: '',
    activeTab: 0,
    step: 0,
    isPasswordsMatch: undefined,
    isYubiKeyIdValid: undefined,
    passwordSalt: randomstring.generate(16),
    totpSecret: speakeasy.generateSecret().base32,
    loading: false,
    isModalActive: false,
    copied: false
  }
  const [isAuthenticated, setIsAuthenticated] = useState(undefined)
  const [values, setValues] = useState(initialState)

  useEffect(() => {
    const checkUserIsAuth = async () => {
      //Initialise Firebase
      if (process.env.NODE_ENV === 'production') {
        await fetch('/__/firebase/init.json').then(async response => {
          firebase.initializeApp(await response.json())
        })
      }
      if (process.env.NODE_ENV === 'development') {
        await firebase.initializeApp(firebaseConfig)
      }
      const isUserAuthenticated = !!(await firebase.auth().currentUser)

      return setIsAuthenticated(isUserAuthenticated)
    }
    checkUserIsAuth()
  }, [])

  const TOTP = `otpauth://totp/OpenVPN:${values.username}?secret=${values.totpSecret}&issuer=Panubo`

  //Methods
  const switchTab = (event, newValue) =>
    setValues({
      ...values,
      activeTab: newValue,
      password: '',
      passwordConfirmation: '',
      yubikeyId: '',
      isPasswordsMatch: undefined,
      isYubiKeyIdValid: undefined
    })

  const closeModal = () => setValues({ ...values, isModalActive: false })

  //Navigate through the form
  const handleNext = () => {
    if (values.step === 1 && !checkFieldsValidation()) return false
    if (values.step === 2) return handleSubmit()
    return setValues({ ...values, step: values.step + 1 })
  }

  const handleBack = () => setValues({ ...values, step: values.step - 1 })

  const handleReset = () => setValues(initialState)

  const validateYubiKey = () =>
    setValues({ ...values, isYubiKeyIdValid: values.yubikeyId.length === 44 })

  const handleChange = name => event =>
    setValues({
      ...values,
      [name]: event.target.value,
      isPasswordsMatch: undefined,
      isYubiKeyIdValid: undefined
    })

  const checkFieldsValidation = () => {
    const { activeTab, password, passwordConfirmation, isYubiKeyIdValid } = values

    if (password !== passwordConfirmation) {
      setValues({ ...values, isPasswordsMatch: false })
      return false
    }
    if (activeTab === 1 && isYubiKeyIdValid === false) {
      setValues({ ...values, isYubiKeyIdValid: false })
      return false
    }

    return true
  }

  const getToken = () => firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)

  const getPayload = () => {
    //Initialise payload with default fields
    const payload = {
      username: values.username,
      passwordHash: values.password && sha512crypt(values.password, values.passwordSalt),
      comments: values.comments
    }

    //Add Yubikey Key or Google TOTP depending on the user enrol type
    if (values.activeTab === 1) payload.yubikeyId = values.yubikeyId.slice(0, 12)
    if (values.activeTab === 2) payload.totpSecret = values.totpSecret

    return payload
  }

  const handleSubmit = async () => {
    //Check fields validation
    if (!checkFieldsValidation()) return false

    setValues({ ...values, loading: true })

    try {
      const idToken = await getToken()

      const payload = getPayload()

      const res = await axios.post(`${apiURL}/enrol`, payload, {
        headers: { Authorization: `Bearer ${idToken}` }
      })

      //If error stop spinning wheel and display alert message
      if (res.status !== 200) {
        setValues({ ...values, loading: false })
        return alert('An error occured, try again or contact team@panubo.com')
        //Otherwise display the modal with the resulting file reference
      } else
        setValues({
          ...values,
          loading: false,
          enrolRef: res.data.uuid,
          isModalActive: true,
          step: 3
        })
    } catch (error) {
      setValues({ ...values, loading: false })
      console.error(error)
      return alert('An error occured, try again or contact team@panubo.com')
    }
  }

  if (isAuthenticated === undefined) return false

  console.log('Rendering App', values, isAuthenticated, process.env, firebase)

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <Typography variant="h4" style={{ marginBottom: '1rem', textAlign: 'center' }}>
          Panubo Enrol
        </Typography>
        <Modal
          isModalActive={values.isModalActive}
          closeModal={closeModal}
          refNumber={values.enrolRef}
          copied={values.copied}
          setCopied={values.setCopied}
        />
        {isAuthenticated === false ? (
          <GoogleAuth
            firebase={firebase}
            setIsAuthenticated={setIsAuthenticated}
            values={values}
            setValues={setValues}
          />
        ) : (
          <Steppers
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
            step={values.step}
            handleSubmit={handleSubmit}
            loading={values.loading}>
            <EnrolmentForm
              values={values}
              validateYubiKey={validateYubiKey}
              TOTP={TOTP}
              handleChange={handleChange}
              switchTab={switchTab}
              handleSubmit={handleSubmit}
            />
          </Steppers>
        )}
      </Grid>
      <Grid item xs={3}>
        <Faqs />
      </Grid>
    </Grid>
  )
}

export default App
